import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import { elementScrollIntoView } from "seamless-scroll-polyfill"
import Arrow from "../../../static/svg/arrow.svg"

const useStyles = makeStyles(theme => ({
  link: {
    outline: "none",
    background: "none",
    border: "none",
    padding: 0,
    cursor: "pointer",
    textAlign: "left",

    "&:hover #icon": {
      marginTop: -4.2,
      marginLeft: 10.2,
      transform: "scale(1.3)",
    },
  },
  root: {
    display: "flex",
  },
  text: {
    fontSize: "1.38vw",
    "@media (orientation: portrait) and (max-width:1024px)": {
      fontSize: "2.6vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      fontSize: "3.12vw",
    },
  },
  icon: {
    marginTop: 0,
    marginLeft: 6,
    transition: ".3s all ease",
    transform: "scale(1)",

    width: "0.97vw",
    "@media (orientation: portrait) and (max-width:1024px)": {
      width: "1.82vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      width: "4.37vw",
    },
  },
}))

export default function Link({ name, link }) {
  const [hover, setHover] = React.useState(false)

  const classes = useStyles({ hover })

  return (
    <button
      aria-label={link}
      className={classes.link}
      onClick={() =>
        elementScrollIntoView(document.querySelector(`#${link}`), {
          behavior: "smooth",
        })
      }
    >
      <div className={classes.root}>
        <Typography className={classes.text}>{name}</Typography>

        <div id="icon" className={classes.icon}>
          <Arrow />
        </div>
      </div>
    </button>
  )
}
