import React from "react"
import { graphql, navigate } from "gatsby"

import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage } from "gatsby-plugin-image"
import { Typography } from "@material-ui/core"

import SEO from "../components/seo"
import Button from "../components/qrPage/button"
import Link from "../components/qrPage/link"
import FormCard from "../components/productCard/form"

const useStyles = makeStyles(theme => ({
  "@ global": {
    body: {
      height: 0,
    },
  },
  wrapper: {
    background: theme.palette.background.main,
    boxSizing: "border-box",

    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",

    padding: "8.77vh 0",
    "@media (orientation: portrait) and (max-width:1024px)": {
      padding: "7.55vw 0",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      padding: "7.81vw 0",
    },
  },
  imageLeft: {
    position: "absolute",
    zIndex: -1,
    top: "50%",
    left: 0,
    transform: "translateY(-50%)",

    width: "9.79vw",
    height: "37.84vw",
    "@media (orientation: portrait) and (max-width:1024px)": {
      width: "12.36vw",
      height: "60.41vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      width: "19.06vw",
      height: "79.68vw",
    },
  },
  imageRight: {
    position: "absolute",
    zIndex: -1,
    top: "50%",
    right: 0,
    transform: "translateY(-50%)",

    width: "14.72vw",
    height: "45.62vw",
    "@media (orientation: portrait) and (max-width:1024px)": {
      width: "24.73vw",
      height: "77.99vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      width: "29.37vw",
      height: "69.25vw",
    },
  },

  content: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 auto",
    height: "100%",

    width: "74.65vw",
    "@media (orientation: portrait) and (max-width:1024px)": {
      flexDirection: "column-reverse",
      alignItems: "center",
      width: "82.94vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      width: "86.56vw",
      justifyContent: "center",
    },
  },
  content_left: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    width: "35.41vw",
    height: "100%",
    "@media (orientation: portrait) and (max-width:1024px)": {
      marginTop: "7.81vw",
      width: "100%",
      height: "auto",
      justifyContent: "flex-start",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      marginTop: "4.55vw",
    },
  },
  reserveBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",

    "@media (orientation: portrait) and (max-width:1024px)": {
      alignItems: "center",
    },
  },
  reserveTitle: {
    fontWeight: 400,
    fontFamily: "'Krona One'",
    lineHeight: 1.5,

    fontSize: "3.77vh",
    "@media (orientation: portrait) and (max-width:1024px)": {
      width: "61.45vw",
      fontSize: "2.6vw",
      textAlign: "center",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      width: "86.56vw",
      fontSize: "3.75vw",
    },
  },
  reserveText: {
    fontWeight: 300,
    lineHeight: 1.5,
    textAlign: "left",

    marginTop: "7.22vh",
    fontSize: "1.38vw",
    "@media (orientation: portrait) and (max-width:1024px)": {
      marginTop: "4.55vw",
      order: 2,
      fontSize: "2.6vw",
      textAlign: "center",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      marginTop: "4.55vw",
      fontSize: "3.12vw",
    },

    "& *": {
      margin: 0,
    },

    "& span": {
      color: "#00FF85",
    },
  },
  buttonContent: {
    justifyContent: "center",

    marginTop: "7.22vh",
    fontSize: "0.97vw",
    width: "20.41vw",
    "@media (orientation: portrait) and (max-width:1024px)": {
      marginTop: "4.55vw",
      width: "35.41vw",
      fontSize: "1.82vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      marginTop: "4.55vw",
      width: "45.93vw",
      fontSize: "2.67vw",
    },
  },
  linksBlock: {
    display: "flex",
    justifyContent: "flex-start",

    marginTop: "3.56vh",
    "@media (orientation: portrait) and (max-width:1024px)": {
      justifyContent: "center",
      marginTop: "7.81vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      marginTop: "4.55vw",
    },

    "& > *:not(:first-child)": {
      marginLeft: "4.56vw",
      "@media (orientation: portrait) and (max-width:1024px)": {
        marginLeft: "6.86vw",
      },
      "@media (orientation: portrait) and (max-width:767px)": {
        marginLeft: "5.36vw",
      },
    },
  },

  content_right: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    width: "31.94vw",
    height: "100%",
    "@media (orientation: portrait) and (max-width:1024px)": {
      width: "100%",
      height: "auto",
    },
  },
  logo: {
    background: "transparent",
    border: "none",
    outline: "none",
    cursor: "pointer",

    alignSelf: "flex-end",
    "@media (orientation: portrait) and (max-width:1024px)": {
      alignSelf: "center",
    },

    "& img": {
      width: "19.55vh",
      height: "6.44vh",
      "@media (orientation: portrait) and (max-width:1024px)": {
        alignSelf: "center",
        width: "22.91vw",
        height: "7.55vw",
      },
      "@media (orientation: portrait) and (max-width:767px)": {
        width: "29.06vw",
        height: "9.55vw",
      },
    },
  },
  formBlock: {
    display: "flex",
    flexDirection: "column",

    marginTop: "3.56vh",
    alignItems: "flex-start",
    "@media (orientation: portrait) and (max-width:1024px)": {
      alignItems: "center",
      marginTop: "7.81vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      marginTop: "4.55vw",
    },
  },
  titleForm: {
    fontWeight: 400,
    fontFamily: "'Krona One'",
    lineHeight: 1.5,

    fontSize: "3.77vh",
    "@media (orientation: portrait) and (max-width:1024px)": {
      textAlign: "center",
      fontSize: "2.6vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      width: "57.81vw",
      fontSize: "3.75vw",
    },
  },
  subtitleForm: {
    fontWeight: 300,
    lineHeight: 1.5,
    textAlign: "left",
    color: "#ACACAC",

    marginTop: "0.77vh",
    fontSize: "1.25vw",
    "@media (orientation: portrait) and (max-width:1024px)": {
      textAlign: "center",
      marginTop: "2.48vw",
      fontSize: "2.6vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      marginTop: "0.5vw",
      fontSize: "3.12vw",
    },
  },
  formWrapper: {
    marginTop: "4.33vh",
    "@media (orientation: portrait) and (max-width:1024px)": {
      marginTop: "2.19vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      marginTop: "4.55vw",
    },

    "& > form": {
      "@media (orientation: landscape)": {
        alignItems: "flex-start",
      },

      "& input": {
        boxSizing: "border-box",

        width: "27.01vw",
        height: "2.5vw",
        padding: "0.64vw 1.33vw",
        fontSize: "0.97vw",
        "@media (orientation: portrait) and (max-width: 1024px)": {
          width: "44.27vw",
          height: "4.76vw",
          padding: "1.21vw 2.51vw",
          fontSize: "1.82vw",
        },
        "@media (orientation: portrait) and (max-width: 767px)": {
          width: "67.53vw",
          height: "7.27vw",
          padding: "1.91vw 3.82vw",
          fontSize: "2.67vw",
        },
      },

      "& div": {
        marginTop: "3.5vh",
        "@media (orientation: portrait) and (max-width: 767px)": {
          marginTop: "3.5vw",
        },
      },

      "& button": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        boxSizing: "border-box",

        width: "22.67vw",
        height: "2.5vw",
        padding: "0.64vw 1.33vw",
        fontSize: "0.97vw",
        "@media (orientation: portrait) and (max-width: 1024px)": {
          width: "37.1vw",
          height: "4.76vw",
          padding: "1.21vw 2.51vw",
          fontSize: "1.82vw",
        },
        "@media (orientation: portrait) and (max-width: 767px)": {
          width: "56.68vw",
          height: "7.27vw",
          padding: "1.91vw 3.82vw",
          fontSize: "2.67vw",
        },

        "&::placeholder": {
          fontSize: "inherit",
        },
      },

      "& p": {
        fontSize: "0.97vw",
        "@media (orientation: portrait) and (max-width: 1024px)": {
          fontSize: "1.82vw",
        },
        "@media (orientation: portrait) and (max-width: 767px)": {
          fontSize: "2.67vw",
        },
      },
    },
  },

  networkBlock: {
    display: "flex",
    flexDirection: "column",

    "@media (orientation: portrait) and (max-width:1024px)": {
      alignItems: "center",
    },
  },
  socialMediaTitle: {
    fontWeight: 300,
    lineHeight: 1.5,
    textAlign: "left",

    marginTop: "3.56vh",
    fontSize: "1.38vw",
    "@media (orientation: portrait) and (max-width:1024px)": {
      marginTop: "4.55vw",
      fontSize: "2.6vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      marginTop: "4.55vw",
      fontSize: "3.12vw",
    },

    "& p": {
      margin: 0,
    },
  },
  socialNetworks: {
    display: "flex",

    marginTop: "3.56vh",
    "@media (orientation: portrait) and (max-width:1024px)": {
      marginTop: "4.22vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      marginTop: "2vw",
    },
  },
  socialNetworksItem: {
    display: "block",
    textDecoration: "none",
    cursor: "pointer",

    transition: ".3s all ease",
    "-webkit-transition": ".3s all ease",

    marginRight: "1.78vw",
    "@media (orientation: portrait) and (max-width:1024px)": {
      marginRight: "3.35vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      marginRight: "4.58vw",
    },

    "&:hover": {
      transform: "scale(1.1)",
      "-webkit-transform": "scale(1.1)",
    },

    "&:last-child": {
      marginRight: 0,
    },
  },
  socialNetworksImg: {
    height: "auto",

    width: "4.4vw",
    "@media (orientation: portrait) and (max-width:1024px)": {
      width: "8.26vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      width: "11.97vw",
    },
  },
}))

export default function QrComp({ data: { prismicHeader, prismicQrPage } }) {
  const classes = useStyles()

  const logo = prismicHeader.data.logo
  const title = prismicQrPage.data.title
  const buttonContent = prismicQrPage.data.content_button
  const reserveText = prismicQrPage.data.reserve_text
  const links = prismicQrPage.data.body.filter(
    slice => slice.slice_type === "link"
  )

  const titleForm = prismicQrPage.data.title_form
  const subtitleForm = prismicQrPage.data.subtitle_form
  const inputEmail = prismicQrPage.data.input_email
  const sendButton = prismicQrPage.data.send_button
  const socialMediaTitle = prismicQrPage.data.social_media_title
  const socialNetworks = prismicQrPage.data.social_networks

  const imageLeft = prismicQrPage.data.image_left
  const imageRight = prismicQrPage.data.image_right

  function goLink(str) {
    if (!(str ?? false)) return

    if (str.slice(0, 4) === "http") {
      const anchor = document.createElement("a")
      anchor.href = str
      anchor.click()
    } else {
      navigate(str)
    }
  }

  React.useEffect(() => {
    window.onload = function () {
      console.log("onload")
      hideAddressBar()
      window.addEventListener(
        "orientationchange",
        function () {
          hideAddressBar()
        },
        false
      )
    }

    function hideAddressBar() {
      setTimeout(function () {
        document.body.style.height = window.outerHeight + "px"
        setTimeout(function () {
          window.scrollTo(0, 1)
        }, 1100)
      }, 1000)
      return false
    }
  }, [])

  return (
    <div className={classes.wrapper}>
      <SEO title={"PWRBOARD"} />

      <GatsbyImage
        image={imageLeft.localFile?.childImageSharp.gatsbyImageData}
        alt={imageLeft.alt}
        className={classes.imageLeft}
        imgStyle={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          objectPosition: "left",
        }}
      />

      <GatsbyImage
        image={imageRight.localFile?.childImageSharp.gatsbyImageData}
        alt={imageRight.alt}
        className={classes.imageRight}
        imgStyle={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          objectPosition: "right",
        }}
      />

      <div className={classes.content}>
        <div className={classes.content_left}>
          <div className={classes.reserveBlock}>
            <Typography className={classes.reserveTitle}>{title}</Typography>

            <Typography className={classes.reserveText} component="div">
              <div
                dangerouslySetInnerHTML={{
                  __html: reserveText.html.includes("<pre>")
                    ? reserveText.text
                    : reserveText.html,
                }}
              />
            </Typography>

            <Button
              onClick={() => goLink(prismicQrPage.data.button_link.url)}
              className={classes.buttonContent}
            >
              {buttonContent}
            </Button>
          </div>

          <div className={classes.linksBlock}>
            {links.map(link => (
              <Link
                name={link.primary.link_name}
                link={link.primary.link_content.url}
                key={link.id}
              />
            ))}
          </div>
        </div>

        <div className={classes.content_right}>
          <button
            name="home"
            aria-label="go home"
            onClick={() => goLink("/")}
            className={classes.logo}
          >
            <img
              src={logo.localFile.publicURL}
              alt={logo.alt}
              width={176}
              height={58}
            />
          </button>

          <div className={classes.formBlock}>
            <Typography className={classes.titleForm}>{titleForm}</Typography>

            {subtitleForm ? (
              <Typography className={classes.subtitleForm}>
                {subtitleForm}
              </Typography>
            ) : null}

            <div className={classes.formWrapper}>
              <FormCard
                placeholder={inputEmail}
                buttonText={sendButton}
                listFields={{ page: "/qr" }}
              />
            </div>
          </div>

          <div className={classes.networkBlock}>
            <Typography className={classes.socialMediaTitle}>
              <div
                dangerouslySetInnerHTML={{
                  __html: socialMediaTitle.html.includes("<pre>")
                    ? socialMediaTitle.text
                    : socialMediaTitle.html,
                }}
              />
            </Typography>

            <div className={classes.socialNetworks}>
              {socialNetworks.map((network, i) => (
                <a
                  href={network.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.socialNetworksItem}
                >
                  <img
                    src={network.img_svg_.localFile.publicURL}
                    width="74"
                    height="66"
                    className={classes.socialNetworksImg}
                    alt={network.img_svg_.alt}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  {
    prismicHeader {
      data {
        logo {
          localFile {
            publicURL
          }
          alt
        }
      }
    }
    prismicQrPage {
      data {
        title
        content_button
        button_link {
          url
        }
        reserve_text {
          html
          text
        }
        image_left {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image_right {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        body {
          ... on PrismicQrPageBodyLink {
            id
            slice_type
            primary {
              link_name
              link_content {
                url
              }
            }
          }
        }
        title_form
        subtitle_form
        input_email
        send_button
        social_media_title {
          html
          text
        }
        social_networks {
          link
          img_svg_ {
            alt
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
