import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  root: {
    border: "none",
    outline: "none",
    cursor: "pointer",

    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",

    borderRadius: "1000px",
    background: theme.palette.primary.accent,

    padding: "0.64vw 1.33vw",
    "@media (orientation: portrait) and (max-width: 1024px)": {
      padding: "1.21vw 2.51vw",
    },
    "@media (orientation: portrait) and (max-width: 767px)": {
      padding: "1.91vw 3.82vw",
    },

    "&:hover": {
      background: theme.palette.primary.accent,
    },
  },
  title: {
    lineHeight: 1,
    fontFamily: "Krona One",
    textTransform: "none",
    color: "#000",

    fontSize: "0.97vw",
    "@media (orientation: portrait) and (max-width:1024px)": {
      fontSize: "1.82vw",
    },
    "@media (orientation: portrait) and (max-width:767px)": {
      fontSize: "2.67vw",
    },
  },
}))

export default function Button({ children, className, onClick }) {
  const classes = useStyles()

  return (
    <button
      onClick={onClick}
      className={classes.root + " " + className}
      aria-label="Form Button"
    >
      <Typography className={classes.title}>{children}</Typography>
    </button>
  )
}
